<template>
  <!-- 销售区域 zz201110-->
  <div class="wrapper">
    <!--  -->
    <el-row type="flex" class="row-bg btncls" justify="end">
      <el-col :span="2">
        <el-button @click="addarea">添加区域</el-button>
      </el-col>
    </el-row>
    <!--  -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="添加区域"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="form">
        <el-row :gutter="20">
          <el-col :span="16" :offset="2">
            <el-form-item label="*区域名称：" :label-width="formiWidth2">
              <el-input v-model="form.area_name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="上级区域：" :label-width="formiWidth2">
              <el-cascader
                v-model="values"
                :options="options"
                :props="{
                  checkStrictly: true,
                  value: 'id',
                  label: 'area_name',
                }"
                clearable
                @change="handleChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="备注：" :label-width="formiWidth2">
              <el-input v-model="form.area_remark" type="textarea"></el-input>
            </el-form-item>
            <el-form-item label="*状态：" :label-width="formiWidth2">
              <el-radio-group v-model="form.is_closed">
                <el-radio :label="0">正常</el-radio>
                <el-radio :label="1">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="clientlevelCreate_fun(saveType)">
          保存
        </el-button>
        <el-button @click="dialogFormVisible = false">关闭</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-table
      v-loading="loading"
      stripe
      :data="tableData1"
      style="width: 100%"
      row-key="id"
      border
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <!-- 排序 -->
      <!-- <el-table-column prop="" label="排序" width="80" type="">
        <template #default="{ row }">
          <el-input v-model.trim="row.sort" @blur="blur_"></el-input>
        </template>
      </el-table-column> -->
      <el-table-column
        type="index"
        label="序号"
        width="50"
        align="center"
      ></el-table-column>
      <!-- <el-table-column
        type="selection"
        width="55"
        align="center"
      ></el-table-column> -->
      <el-table-column
        prop="area_name"
        label="销售区域"
        width="180"
      ></el-table-column>

      <el-table-column prop="depart" label="关联部门"></el-table-column>
      <el-table-column prop="is_closed_text" label="状态"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEdit(scope.$index, scope.row)">
            编辑
          </el-button>
          <el-button type="text" @click="handleDelete(scope.$index, scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  import {
    dealerAreaList,
    dealerAreaCreate,
    dealerAreaUpdate,
    dealerAreaDetail,
    dealerAreaDelete,
    dealerAreaAreaList,
  } from '@/api/SaleArea'
  export default {
    name: 'SaleArea',
    components: {},
    data() {
      return {
        loading: true,
        values: [],
        options: [],
        tableData1: [],
        dialogTableVisible: false,
        dialogFormVisible: false,
        saveType: 0, //2,编辑入口(保存) ，0，添加入口（保存）=[!2，不显示“保存并新增”]
        form: {
          area_name: '',
          area_remark: '',
          area_parent: '',
          is_closed: 0,
        },
        formLabelWidth: '160px',
        formiWidth2: '120px',
        formiWidth: '200px',
        listdata: {
          pageNo: 0,
          pageSize: 50,
        },
      }
    },
    computed: {},
    watch: {
      dialogFormVisible(v) {
        if (v) {
          this.dealerAreaAreaListfun()
        }
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      //添加区域中 选择上级区域值
      handleChange(value) {
        var that = this
        that.form.area_parent = value[value.length - 1]
      },
      clientlevelCreate_fun(types) {
        //添加入口1，保存并新增 0，保存
        // 编辑入口 2，保存
        var that = this
        if (that.form.area_name == '') {
          this.$alert('<p>请输入区域名称</p>', '提示', {
            dangerouslyUseHTMLString: true,
          })
        }
        //  else if (that.form.area_parent == '') {
        //   this.$alert('<p>请选择上级区域</p>', '提示', {
        //     dangerouslyUseHTMLString: true,
        //   })
        // }
        else if (that.form.is_closed == 3) {
          this.$alert('<p>请选择状态</p>', '提示', {
            dangerouslyUseHTMLString: true,
          })
        } else {
          if (types == 2) {
            dealerAreaUpdate(that.form).then((d) => {
              if (d.code == 200) {
                that.fetchData()
                that.dialogFormVisible = false
              }
            })
          } else {
            dealerAreaCreate(that.form).then((d) => {
              if (d.code == 200) {
                that.fetchData()
                that.dialogFormVisible = false
              }
            })
          }
        }
      },
      // 获取销售区域列表
      async fetchData() {
        let { data, totalCount } = await dealerAreaList(this.listdata)
        this.tableData1 = data
        this.loading = false
      },
      //获取上级区域数据
      dealerAreaAreaListfun() {
        var that = this
        dealerAreaAreaList().then((d) => {
          if (d.code == 200) {
            console.log(d)
            that.options = d.data
          }
        })
      },
      // 编辑入口保存
      handleEdit(index, row) {
        var that = this
        dealerAreaDetail({ id: row.id }).then((d) => {
          console.log(d)
          if (d.code == 200) {
            that.form = d.data
            that.values = d.data.id
            console.log(d.data.id)
          }
        })
        that.saveType = 2
        that.dialogFormVisible = true
      },
      handleDelete(index, row) {
        console.log(index, row)
        var that = this
        that
          .$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            dealerAreaDelete({ id: row.id }).then((d) => {
              if (d.code == 200) {
                that.fetchData()
                that.$message({
                  type: 'success',
                  message: '删除成功!',
                })
              }
            })
          })
          .catch(() => {
            that.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      // 排序输入框失去焦点 排序
      blur_() {
        this.sort_()
      },
      // 排序
      sort_() {
        this.tableData1 = _.sortBy(this.tableData1, (item) => item.sort)
      },
      addarea() {
        var that = this
        that.saveType = 0
        that.values = []
        that.dialogFormVisible = true
        that.form = {
          area_name: '',
          area_remark: '',
          area_parent: '',
          is_closed: 0,
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    padding: 20px;
  }
  .btncls {
    margin: 10px 0;
  }
</style>
