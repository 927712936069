// 销售区域
import request from '@/utils/request'
// 获取销售区域列表
export function dealerAreaList(data) {
  return request({
    url: '/baseAdmin/dealer-area/index',
    method: 'post',
    data,
  })
}
//新增区域 保存
export function dealerAreaCreate(data) {
  return request({
    url: '/baseAdmin/dealer-area/create',
    method: 'post',
    data,
  })
}
//编辑区域 保存
export function dealerAreaUpdate(data) {
  return request({
    url: '/baseAdmin/dealer-area/update',
    method: 'post',
    data,
  })
}
// 销售区域详情
export function dealerAreaDetail(data) {
  return request({
    url: '/baseAdmin/dealer-area/detail',
    method: 'post',
    data,
  })
}
//删除区域
export function dealerAreaDelete(data) {
  return request({
    url: '/baseAdmin/dealer-area/delete',
    method: 'post',
    data,
  })
}
//获取上级区域数据
export function dealerAreaAreaList(data) {
  return request({
    url: '/baseAdmin/common/area-list',
    method: 'post',
    data,
  })
}
